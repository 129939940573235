import React from "react";
import brian from "../assets/images/brian.jpg";
import stuart from "../assets/images/stuart1.jpg";
import teekay from "../assets/images/teekay.jpg";
import martin from "../assets/images/martin.jpg";
import desire from "../assets/images/desire.jpg";
import prisca from "../assets/images/prisca.jpg";
import mobileapp from "../assets/images/mobile.png";
import kalidozo from "../assets/images/kalidozo.jpg";
import valentino from "../assets/images/mr_majawa.jpg";
import { IoAdd, IoLogoInstagram, IoLogoLinkedin } from "react-icons/io5";
import { FiTriangle, FiCircle } from "react-icons/fi";
import { BsCircleFill } from "react-icons/bs";
import { GiCircle } from "react-icons/gi";
import { CiFacebook } from "react-icons/ci";
import { BsGithub } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";

function About() {
  return (
    <div>
      <div
        className="bg-slate-100"
        style={{ zIndex: 1, paddingTop: "10%", paddingBottom: "150px" }}
      >
        <FiTriangle
          size={24}
          className="text-green-500 left-10 sm:left-40 absolute animate-bounce"
          style={{ animationDuration: "4s", marginTop: "" }}
        />
        <IoAdd
          size={24}
          className="text-green-500 mt-[35%] sm:mt-[-6%] right-[50%] sm:right-56 absolute animate-spin"
          style={{ animationDuration: "4s" }}
        />
        <div
          className="flex flex-col sm:flex-row items-center justify-center mx-auto"
          style={{ width: "90%" }}
        >
          <h1 className="text-3xl font-[700] text-[#0e314c]">About Us</h1>
        </div>
        <GiCircle
          size={24}
          className="right-[20%] mt-[13%] sm:right-96 absolute animate-ping text-green-500"
          style={{ animationDuration: "4s" }}
        />
        <BsCircleFill
          size={24}
          className="right-[20%] mt-[13%] sm:right-96 absolute opacity-50 text-green-500"
          style={{ animationDuration: "4s" }}
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center my-4">
        <div className="w-full md:w-6/12 order-2 sm:order-1">
          <img src={mobileapp} alt="" className="mx-auto sm:h-[650px]" />
        </div>
        <div className="w-full pt-[50px] md:w-6/12 px-3 sm:px-1 order-1 sm:order-2">
          <h2
            className="font-[500] text-xl sm:text-2xl sm:w-3/5"
            style={{ color: "#0e314c" }}
          >
            Who we are
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-4/5 my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            Vuka Umlimi, a Zulu term meaning "wake up the farmer," is an
            innovative agricultural company that was founded in 2022. The
            establishment of our company was driven by the successful
            development of a poultry farm monitoring and controlling system.
            With a vision to revolutionize the agricultural industry, we
            commenced operations with a single individual and have since
            expanded into a dynamic team of experts in various fields.
          </p>
          <p
            className="text-[14px] sm:text-sm sm:w-4/5 my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            Our current portfolio encompasses several ambitious agricultural
            projects, each designed to address specific challenges faced by
            farmers and optimize their agricultural operations. By harnessing
            the power of advanced technologies such as artificial intelligence,
            data analytics, and automation, we aim to provide farmers with
            intuitive tools and solutions that enhance farm productivity, crop
            yield, and livestock health.
          </p>
          <p
            className="text-[14px] sm:text-sm sm:w-4/5 my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            Through our innovative approach, we seek to streamline processes,
            minimize resource wastage, and mitigate risks associated with
            farming, ultimately contributing to a sustainable and prosperous
            agricultural sector. Vuka Umlimi remains dedicated to empowering
            farmers with the knowledge, tools, and support necessary to thrive
            in the rapidly evolving world of agriculture.
          </p>
          <p
            className="text-[14px] sm:text-sm sm:w-4/5 my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            We strive to foster a strong bond between technology and
            agriculture, paving the way for a future where farming is both
            technologically advanced and environmentally conscious.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 pt-10 pb-10 px-5 sm:px-10">
        <div>
          <h2
            className="font-[500] text-xl sm:text-2xl sm:w-3/5"
            style={{ color: "#0e314c" }}
          >
            Our Vision
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-full my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            Our vision is a future where traditional constraints are shattered,
            and farmers harness innovation to maximize yields, optimize
            resources, and ensure top-quality produce. With comprehensive
            monitoring systems and diverse agricultural products, we lead
            sustainable practices, making a positive global impact. As a trusted
            global leader, we revolutionize farming, driving profitable and
            sustainable practices making a significant difference in the lives
            of farmers and the communities they serve.
          </p>
        </div>
        <div>
          <h2
            className="font-[500] text-xl sm:text-2xl sm:w-3/5"
            style={{ color: "#0e314c" }}
          >
            Our Mission
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-full my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            The mission of VUKA UMLIMI is to revolutionize agricultural
            production and empower farmers through innovative technology
            solutions and the creation of high-quality agricultural products. We
            aim to simplify and enhance the farming experience by providing
            comprehensive systems for farms. We are committed to driving
            increased productivity, efficiency, and sustainability in the
            agricultural sector while fostering a sense of community and support
            among farmers. Our ultimate goal is to contribute to the global food
            security and improve the livelihoods of farmers worldwide
          </p>
        </div>
        <div>
          <h2
            className="font-[500] text-xl sm:text-2xl sm:w-3/5"
            style={{ color: "#0e314c" }}
          >
            Operating principles
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-full my-4"
            style={{ color: "#6084a4", lineHeight: 1.8 }}
          >
            We are guided by a powerful principle: "All We Need Is a Little
            Push." This motto represents our unwavering belief that success is
            attainable when individuals receive the right amount of
            encouragement and support. We are dedicated to providing farmers
            with the tools, technology, and resources they need to thrive in the
            ever-evolving agricultural landscape. Through innovative solutions
            and comprehensive support systems, we empower farmers to overcome
            challenges and unlock their full potential. By fostering a
            collaborative and inclusive environment, we aim to push the
            boundaries of what is possible in agriculture.
          </p>
        </div>
      </div>
      <div className="bg-[#41B77F] flex justify-center items-center">
        <h1 className="text-2xl sm:text-4xl text-white py-10 sm:py-20 px-auto font-[600]">
          "All we need is a little push"
        </h1>
      </div>
      <div className="flex items-center flex-col my-10">
        <h1
          className="text-xl sm:text-2xl font-[600]"
          style={{ color: "#0e314c" }}
        >
          Our Awesome Team
        </h1>
        <p
          className="px-3 sm:px-0 sm:w-8/12 text-center text-xs sm:text-sm mb-12"
          style={{ color: "#6084a4", lineHeight: 1.8 }}
        >
          Our awesome team comprises passionate individuals who are dedicated to
          driving agricultural innovation and making a positive impact in the
          farming community. With diverse expertise and a shared commitment to
          excellence, we work collaboratively to deliver cutting-edge solutions
          that empower farmers worldwide.
        </p>
        {/* <div className="grid grid-cols-1 sm:grid-cols-4 gap-12 pt-10 pb-10 px-5 sm:px-10">
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={teekay}
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062] object-cover"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Takondwa Caesar Ndawala</p>
              <span className="text-[13px]">Founder & CEO</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="https://www.linkedin.com/in/takondwa-ndawala-834142222">
                <IoLogoLinkedin size={24} className="mr-5" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100088010521044&mibextid=ZbWKwL">
                <CiFacebook size={24} className="mr-5" />
              </a>
              <a href="https://github.com/caesar127">
                <BsGithub size={24} className="mr-5" />
              </a>
              <a href="mailto:caesarndawala@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={valentino}
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062]"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Valentino Majawa</p>
              <span className="text-[13px]">Brand Manager</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="https://www.linkedin.com/in/valentino-majawa-ab6382271/">
                <IoLogoLinkedin size={24} className="mr-5" />
              </a>
              <a href="https://instagram.com/highvibrationhim?igshid=MzRlODBiNWFlZA==">
                <IoLogoInstagram size={24} className="mr-5" />
              </a>
              <a href="https://www.facebook.com/vale.mjw.9?mibextid=ZbWKwL">
                <CiFacebook size={24} className="mr-5" />
              </a>
              <a href="mailto:valentinomajawa@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={prisca}
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062] object-cover"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Prisca Mkandawire</p>
              <span className="text-[13px]">
                Chief Marketing Technology Officer
              </span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="https://instagram.com/prisca_ellah?igshid=MzRlODBiNWFlZA==">
                <IoLogoInstagram size={24} className="mr-5" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100037051823567&mibextid=ZbWKwL">
                <CiFacebook size={24} className="mr-5" />
              </a>
              <a href="https://github.com/Prisca077">
                <BsGithub size={24} className="mr-5" />
              </a>
              <a href="mailto:mkandawireprisca98@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={brian}
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062]"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Brian Kaludzu</p>
              <span className="text-[13px]">Meat Trading & Distribution Manager</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="mailto:kaludzubrian@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={kalidozo}
                alt=""
                className="rounded-full h-48 w-48 border-4 border-[#41b78062] object-cover"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Isaac Kalidozo</p>
              <span className="text-[13px]">Media Specialist</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="https://www.facebook.com/kalidozo.isaac?mibextid=ZbWKwL">
                <CiFacebook size={24} className="mr-5" />
              </a>
              <a href="mailto:">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={stuart}
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062]"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Stuart Ngundende</p>
              <span className="text-[13px]">Social Media Manager</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="mailto:stuartngundende265@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={desire}
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062] object-cover"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Desire Chikagwa</p>
              <span className="text-[13px]">Systems Administrator</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="https://github.com/desire-c">
                <BsGithub size={24} className="mr-5" />
              </a>
              <a href="mailto:desirechikagwa004@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src="https://avatars.githubusercontent.com/u/41738925?v=4"
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062] object-cover"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Tinga Warren Chingeni</p>
              <span className="text-[13px]">Software Engineer</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="">
                <BsGithub size={24} className="mr-5" />
              </a>
              <a href="mailto:martinsilungwe12@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
          <div className=" bg-slate-50">
            <div className="p-12 pb-0">
              <img
                src={martin}
                alt=""
                className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062] object-cover"
              />
            </div>
            <div className="mt-8 text-white bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
              <p className="text-[18px]">Martin Silungwe</p>
              <span className="text-[13px]">AI Engineer</span>
            </div>
            <div className="flex justify-center py-8 text-[#23bdb8]">
              <a href="https://github.com/martin265">
                <BsGithub size={24} className="mr-5" />
              </a>
              <a href="mailto:martinsilungwe12@gmail.com">
                <HiOutlineMail size={24} />
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default About;
