import React from "react";
import Contact from "./Contact";
import { IoAdd } from "react-icons/io5";
import { GiCircle } from "react-icons/gi";
import { FiTriangle } from "react-icons/fi";
import easy from "../assets/images/easy.png";
import { BsCircleFill } from "react-icons/bs";
import farmhand from "../assets/images/home3.png";
import { Analytics } from "@vercel/analytics/react";
import mobileapp from "../assets/images/mobile.png";
import greentech from "../assets/images/greentech.png";
import profitable from "../assets/images/profitable.png";
import farm_system from "../assets/images/farm_system.png";

function Services() {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div
        className="bg-slate-100 pt-[20%] pb-[20%] sm:pt-[10%] sm:pb-[10%]"
        style={{ zIndex: 1 }}
      >
        <FiTriangle
          size={24}
          className="text-green-500 left-10 sm:left-40 absolute animate-bounce"
          style={{ animationDuration: "4s", marginTop: "" }}
        />
        <IoAdd
          size={24}
          className="text-green-500 mt-[35%] sm:mt-[-6%] right-[50%] sm:right-56 absolute animate-spin"
          style={{ animationDuration: "4s" }}
        />
        <div
          className="flex flex-col sm:flex-row items-center justify-center mx-auto"
          style={{ width: "90%" }}
        >
          <h1 className="text-3xl font-[700] text-gray-900">Services</h1>
        </div>
        <GiCircle
          size={24}
          className="right-[20%] mt-[13%] sm:right-96 absolute animate-ping text-green-500"
          style={{ animationDuration: "4s" }}
        />
        <BsCircleFill
          size={24}
          className="right-[20%] mt-[13%] sm:right-96 absolute opacity-50 text-green-500"
          style={{ animationDuration: "4s" }}
        />
      </div>
      <div className="sm:flex items-center mx-auto px-5 sm:px-20 sm:my-24 my-8 ">
        <div className="mr-8 sm:w-1/2">
          <img src={farm_system} alt="" className="mx-auto sm:h-[400px] " />
        </div>
        <div className=" mt-12 sm:mt-0 sm:ml-8 sm:w-1/2">
          <h2 className="font-[700] text-xl sm:text-2xl text-gray-900 mb-3">
            Farm Mangement systems
          </h2>
          <p
            className="text-[0.95rem] mb-5 text-gray-600"
            style={{ lineHeight: "1.75rem" }}
          >
            Our farm management system will help you as a farmer to keep track
            of all your farm resources through monitoring analysed data. this
            allows you to have a more accurate understanding of your farm and
            helps you make more accurate decisions to move forward. Risks taken
            with using farm management systems are far much rewarding than those
            without.
          </p>
          <button
            onClick={scrollToContact}
            className="bg-green-500 text-white rounded-lg py-2 px-4"
          >
            Optimize farm
          </button>
        </div>
      </div>
      <div className="sm:flex items-center mx-auto px-5 sm:px-20 sm:my-24 my-8 bg-slate-100 py-20">
        <div className="sm:w-1/2 sm:order-last">
          <img src={farmhand} alt="" className="mx-auto sm:h-[300px]" />
        </div>
        <div className="mt-12 sm:mt-0 sm:ml-8 sm:w-1/2">
          <h2 className="font-[700] text-xl sm:text-2xl text-gray-900 mb-3">
            Smarter Supply Chain
          </h2>
          <p
            className="text-[0.95rem] mb-5 text-gray-600"
            style={{ lineHeight: "1.75rem" }}
          >
            With our smart supply chain platform will help you simplify
            marketing and selling of farm produce. Imagine selling all your
            goods on the market without even reaching the market physically.
            this smart supply chain enables farmers to focus on farming and not
            worry about how and where to sell out their produce. The smart
            supply chain also enables farmers to have a targeted market for
            their produce rather than dealing with new situations everytime.
          </p>
          <button
            onClick={scrollToContact}
            className="bg-green-500 text-white rounded-lg py-2 px-4"
          >
            Optimize farm
          </button>
        </div>
      </div>

      <div className="sm:flex items-center mx-auto px-5 sm:px-20 sm:my-24 my-8">
        <div className="mr-8 sm:w-1/2">
          <img src={mobileapp} alt="" className="mx-auto sm:h-[500px] " />
        </div>
        <div className=" mt-12 sm:mt-0 sm:ml-8 sm:w-1/2">
          <h2 className="font-[700] text-xl sm:text-2xl text-gray-900 mb-3">
            Easy Custom Software
          </h2>
          <p
            className="text-[0.95rem] mb-5 text-gray-600"
            style={{ lineHeight: "1.75rem" }}
          >
            Easy customized software for your farm will mean having a custom
            made software designed specifically for your farm. This is one of
            the ways a farmer is bound to achieve great yields of success. Its
            another thing when there is a universal software for all farms, but
            when one is made for you specific, it becomes your friend.
          </p>
          <button
            onClick={scrollToContact}
            className="bg-green-500 text-white rounded-lg py-2 px-4"
          >
            Optimize farm
          </button>
        </div>
      </div>
      <div className="items-center mx-auto px-5 sm:px-20 sm:my-24 my-8 bg-slate-100 py-20">
        <h1 className="text-center font-[600] text-3xl mb-8">Why Choose us?</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 mx-auto w-[90%] sm:h-50">
          <div className="bg-white rounded-xl p-4">
            <div className="flex align-middle items-center mb-3">
              <img
                src={easy}
                alt=""
                className="h-10 bg-green-400 rounded-lg p-2"
              />
              <h1 className="font-[600] text-xl ml-4">Easy</h1>
            </div>
            <p
              className="text-[0.95rem] mb-5 text-gray-600"
              style={{ lineHeight: "1.75rem" }}
            >
              Our platforms boast intuitive interfaces designed for effortless
              navigation, ensuring farmers of all levels can efficiently manage
              their operations with ease.
            </p>
          </div>

          <div className="bg-white rounded-xl p-4">
            <div className="flex align-middle items-center mb-3">
              <img
                src={greentech}
                alt=""
                className="h-10 bg-green-400 rounded-lg p-2"
              />
              <h1 className="font-[600] text-xl ml-4">Advanced</h1>
            </div>
            <p
              className="text-[0.95rem] mb-5 text-gray-600"
              style={{ lineHeight: "1.75rem" }}
            >
              With cutting-edge technology and innovative tools, our platforms
              offer advanced functionalities that empower farmers to optimize
              every aspect of their operations for maximum efficiency and
              productivity.
            </p>
          </div>

          <div className="bg-white rounded-xl p-4">
            <div className="flex align-middle items-center mb-3">
              <img
                src={profitable}
                alt=""
                className="h-10 bg-green-400 rounded-lg p-2"
              />
              <h1 className="font-[600] text-xl ml-4">Profitable</h1>
            </div>
            <p
              className="text-[0.95rem] mb-5 text-gray-600"
              style={{ lineHeight: "1.75rem" }}
            >
              By leveraging data-driven insights and strategic planning
              capabilities, our platforms enable farmers to make informed
              decisions that lead to increased profitability and sustainable
              growth.
            </p>
          </div>
        </div>
      </div>
      <Contact />
      <Analytics />
    </div>
  );
}

export default Services;
