import React from "react";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import Topbar from "./Topbar";

const DashboardComponent = () => {
  return (
    <div className="" style={{ background: "rgb(252,252,252)" }}>
      <Sidebar />
      <main className="sm:ml-[265px]">
        <Topbar />
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardComponent;
