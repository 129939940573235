import React from "react";
import { useTheme, Box } from "@mui/material";
import { RiUploadCloud2Fill } from "react-icons/ri";

function ImageUploader({ picture, onPictureChange }) {
  const theme = useTheme();
  const [dragState, setDragState] = React.useState("none");

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragState("drag-enter");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragState("drag-over");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragState("none");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragState("none");

    const file = e.dataTransfer.files[0];
    onPictureChange(file);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    onPictureChange(file);
  };

  return (
    <Box
      component="label"
      className="box"
      variant="text"
      sx={{
        flex: "1 0 auto",
        height: "100px",
        border: "1px dashed",
        borderRadius: "5px",
        background: "#D7D7D7",
        borderColor: "#939393",
        color: "#B0B0B0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!picture ? (
        <>
          <RiUploadCloud2Fill size={32} />
          <p style={{ padding: "0px", margin: "0px" }}>
            {dragState === "drag-over" ? "Drop the image" : "Upload Picture"}
          </p>
          <input
            hidden
            accept="image/*"
            name="picture"
            onChange={handleImageChange}
            type="file"
          />
        </>
      ) : (
        <>
          <img
            src={picture}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "90%",
              objectFit: "contain",
              cursor: "pointer",
            }}
          />
          <input
            hidden
            accept="image/*"
            name="picture"
            onChange={handleImageChange}
            type="file"
            id="imageInput"
          />
        </>
      )}
    </Box>
  );
}

export default ImageUploader;
