import apps from "../assets/images/apps.png";
import supply from "../assets/images/supply-chain.png";
import system from "../assets/images/system-information.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function Features() {
  return (
    <div
      id="enhanceFarm"
      className="flex-col sm:flex items-center mx-auto px-5 sm:px-32 my-10 mb-16"
    >
      <h1 className="font-[600] text-2xl sm:text-4xl mb-6 text-gray-900 text-center">
        Enhance your farm
      </h1>
      <p
        className="text-[0.95rem] text-center w-full sm:w-3/4 mb-20 text-gray-600"
        style={{ lineHeight: "1.75rem" }}
      >
        Transform your farm with our cutting-edge solutions and unlock its full
        potential. With our tailored approach, you can optimize productivity,
        streamline processes, and achieve sustainable success like never before.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mx-auto w-full sm:h-72">
        <div>
          <img
            src={system}
            alt=""
            className="h-10 bg-green-400 rounded-lg p-2 mb-6 "
          />
          <h1 className="font-[600] text-[1.1rem] mb-2 text-gray-900">
            Farm management systems
          </h1>
          <p
            className="text-[0.95rem] mb-5 text-gray-600"
            style={{ lineHeight: "1.75rem" }}
          >
            Streamline farm management with centralized oversight. Plan crops,
            organize supplies, track team tasks, and make informed financial
            decisions for optimal productivity and growth.
          </p>
          <a href="/services" className="text-green-400">
            Learn more <ArrowForwardIcon />
          </a>
        </div>
        <div>
          <img
            src={supply}
            alt=""
            className="h-10 bg-green-400 rounded-lg p-2 mb-6 "
          />
          <h1 className="font-[600] text-[1.1rem] mb-2 text-gray-900">
            Smarter Supply Chain
          </h1>
          <p
            className="text-[0.95rem] mb-5 text-gray-600"
            style={{ lineHeight: "1.75rem" }}
          >
            Optimize farm operations for seamless efficiency. Manage deliveries
            and supplies effectively, maintain real-time inventory tracking,
            streamline produce distribution, and enhance quality control to
            minimize waste and meet deadlines consistently.
          </p>
          <a href="/services" className="text-green-400">
            Learn more <ArrowForwardIcon />
          </a>
        </div>
        <div>
          <img
            src={apps}
            alt=""
            className="h-10 bg-green-400 rounded-lg p-2 mb-6 "
          />
          <h1 className="font-[600] text-[1.1rem] mb-2 text-gray-900">
            Easy Custom Software
          </h1>
          <p
            className="text-[0.95rem] mb-5 text-gray-600"
            style={{ lineHeight: "1.75rem" }}
          >
            Get tailored farm software for seamless integration of tools and
            improved communication through user-friendly apps.
          </p>

          <a href="/services" className="text-green-400">
            Learn more <ArrowForwardIcon />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Features;
