import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CgProfile, CgMenuGridR } from "react-icons/cg";
import { FaUserCircle } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { logout } from "../features/slice/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { IoGridOutline } from "react-icons/io5";

function Topbar() {
  const notificationCount = 3;
  const [openAccount, setOpenAccount] = useState(false);
  const [menuEl, setMenuEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (event) => {
    dispatch(logout());
    navigate("/");
  };

  const handleClick = () => {
    setMenuEl(!menuEl);
  };

  return (
    <div className="flex justify-between py-8" style={{ height: "64px" }}>
      <div className="flex justify-end flex-grow items-center">
        {/* <button className="p-2 w-10 h-10">
          <IoGridOutline className="w-6 h-6 text-gray-600" />
        </button>
        <button className="p-1 relative">
          {notificationCount > 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold leading-tight text-white bg-red-600 rounded-full">
              {notificationCount}
            </span>
          )}
          <IoIosNotificationsOutline className="w-6 h-6 text-gray-600" />
        </button> */}
        <div className="relative">
          <button
            onClick={handleClick}
            className="mx-3 w-9 h-9 rounded-full "
          >
            <FaUserCircle className="text-gray-300" size={36} />
          </button>
          {menuEl && (
            <div className="absolute right-2 mt-1 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
              <Link
                to="account"
                onClick={handleClick}
                className="flex items-center px-4 py-2 text-md m-1 w-[95%] hover:bg-gray-100 rounded-md"
              >
                <CgProfile className="text-gray-400" size={18} />
                <span className="ml-3">Profile</span>
              </Link>

              <button
                onClick={handleLogout}
                className="flex items-center px-4 py-2 text-md m-1 w-[95%] hover:bg-gray-100 rounded-md"
              >
                <CiLogout className="text-gray-400" size={18} />
                <span className="ml-3">Logout</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Topbar;
