import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  posts: [],
  postCount: 0,
  loading: false,
  error: null,
};

const blogPostSlice = createSlice({
  name: "blogPost",
  initialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setPostCount: (state, action) => {
      state.postCount = action.payload.totalCount;
    },
    addPost: (state, action) => {
      state.posts.push(action.payload);
    },
    updatePost: (state, action) => {
      const updatedPost = action.payload;
      const index = state.posts.findIndex((post) => post.id === updatedPost.id);
      if (index !== -1) {
        state.posts[index] = updatedPost;
      }
    },
    removePost: (state, action) => {
      const postId = action.payload;
      state.posts = state.posts.filter((post) => post.id !== postId);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPosts,
  setPostCount,
  addPost,
  updatePost,
  removePost,
  setLoading,
  setError,
} = blogPostSlice.actions;

export default blogPostSlice.reducer;
