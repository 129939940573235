import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : null,
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,
  isAuthenticated: false,
  isSubscribed: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      state.userInfo = null;
      state.token = null;
      state.isAuthenticated = false;
    },
    setCredentials(state, action) {
      const { userInfo, token } = action.payload;
      state.userInfo = userInfo;
      state.token = token;
      state.isAuthenticated = true;
      state.isSubscribed = userInfo.isSubscribed;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      localStorage.setItem("token", JSON.stringify(token));
    },
  },
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;
