import React from 'react';
import construction from '../assets/images/ALL WE NEED_2.png'
function Site() {
    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={construction} alt="Under Construction" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
    );
}

export default Site;
