import React from "react";
import { useTheme } from "@mui/material";

function Dashboard() {
  const theme = useTheme();
  return (
    <div
      className="p-4 rounded-md"
      style={{
        height: "calc(100vh - 64px)",
        background: theme.palette.background.default,
      }}
    >
      Dashboard
    </div>
  );
}

export default Dashboard;
