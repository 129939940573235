export const validateInput = (name, value) => {
  let hasError = false,
    error = "";

  switch (name) {
    case "title":
      if (value.trim() === "") {
        hasError = true;
        error = "Field cannot be empty";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "name":
      if (value.trim() === "") {
        hasError = true;
        error = "Field cannot be empty";
      } else if (!/^[a-zA-Z ]+$/.test(value)) {
        hasError = true;
        error = "Invalid Name. Avoid Special characters";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "subject":
      if (value.trim() === "") {
        hasError = true;
        error = "Field required";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "message":
      if (value.trim() === "") {
        hasError = true;
        error = "Field required";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "url":
      if (value.trim() === "") {
        hasError = true;
        error = "Field cannot be empty";
      } else if (
        !/(^| )(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,8}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(
          value
        )
      ) {
        hasError = true;
        error = "Invalid URL. Please enter a valid URL";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "business_id":
      if (value.trim() === "") {
        hasError = true;
        error = "Field required";
      } else if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
        hasError = true;
        error = "Invalid Entry. Only letters, numbers and spaces are allowed";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "farm_name":
      if (value.trim() === "") {
        hasError = true;
        error = "Field required";
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        hasError = true;
        error = "Invalid Entry. Only letters and spaces are allowed";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "email":
      if (value.trim() === "") {
        hasError = true;
        error = "Field is required";
      } else if (
        !/^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/.test(value)
      ) {
        hasError = true;
        error = "Invalid Email. Please enter a valid email";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "contact":
      if (value.trim() === "") {
        hasError = true;
        error = "Field is required";
      } else if (
        !/^\+(?:[0-9]){1,3}(?:[ \-\(\)])?(?:[0-9]{1,4}(?:[ \-\(\)])?)*[0-9]{1,6}$/.test(
          value
        )
      ) {
        hasError = true;
        error = "Invalid Phone number. Please enter a valid Phone number";
      } else {
        hasError = false;
        error = "";
      }
      break;
    case "password":
      if (value.trim() === "") {
        hasError = true;
        error = "Field is required";
      }

      // else if (!/^[\w@-]{8,20}$/.test(value)) {
      //   hasError = true;
      //   error =
      //     'Password must be alphanumeric (@, _ and - are allowed) and must be 8-20 characters';
      // }
      else {
        hasError = false;
        error = "";
      }
      break;
    case "confirmPassword":
      if (value.trim() === "") {
        hasError = true;
        error = "Field is required";
      } else {
        hasError = false;
        error = "";
      }
      break;
    default:
      break;
  }

  return { hasError, error };
};
