import React from 'react';

function Privacy() {
  return (
    <div>
      <h1>Privacy Policy for Poultry Pro</h1>
      <p>Last Updated: 15 September 2023</p>
      <p>
        This Privacy Policy ("Policy") describes how Vuka Umlimi ("Company," "we," "our," or "us") collects, uses, shares, and protects your personal information when you use the Poultry Pro mobile application (the "App"). By using the App, you agree to the practices described in this Policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        <strong>1.1. Information You Provide:</strong> When you use the App, you may voluntarily provide us with information, including but not limited to your name, contact information, farm details, and other data necessary for app functionality.
      </p>
      <p>
        <strong>1.2. Automatically Collected Information:</strong> We may collect certain information automatically, such as device information (e.g., device type, operating system), usage data, and analytics about your interactions with the App.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        <strong>2.1. We may use the information we collect for various purposes, including but not limited to:</strong>
      </p>
      <ul>
        <li>Providing and maintaining the App's functionality.</li>
        <li>Communicating with you about updates, promotions, and other relevant information.</li>
        <li>Analyzing App usage to improve our services.</li>
        <li>Responding to your inquiries or requests.</li>
      </ul>

      <h2>3. Data Sharing</h2>
      <p>
        <strong>3.1. We do not sell or rent your personal information to third parties. However, we may share your information with:</strong>
      </p>
      <ul>
        <li>Service providers and third-party vendors who assist us in operating the App.</li>
        <li>Law enforcement, government authorities, or legal advisors as required by law or to protect our rights.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        <strong>4.1. We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</strong>
      </p>

      <h2>5. Your Choices</h2>
      <p>
        <strong>5.1. You can access and update your personal information through the App's settings. You may also contact us to request the deletion of your account and associated data.</strong>
      </p>

      <h2>6. Children's Privacy</h2>
      <p>
        <strong>6.1. The App is not intended for use by individuals under the age of [age]. We do not knowingly collect personal information from children under this age. If you believe that we may have collected information from a child, please contact us immediately.</strong>
      </p>

      <h2>7. Changes to this Policy</h2>
      <p>
        <strong>7.1. We reserve the right to update or modify this Policy at any time. Any changes will be posted within the App, and the "Last Updated" date at the beginning of the Policy will be revised accordingly. Your continued use of the App after such changes constitute your acknowledgment and acceptance of the updated Policy.</strong>
      </p>

      <h2>8. Contact Information</h2>
      <p>
        <strong>8.1. If you have questions about this Policy or our data practices, please contact us at [Your Contact Information].</strong>
      </p>

      <p>By using the Poultry Pro app, you consent to the collection and use of your personal information as described in this Privacy Policy. Please read this Policy carefully and ensure your understanding and acceptance of its terms.</p>
    </div>
  );
}

export default Privacy;
