import React from "react";
import logo from "../assets/images/navlogo.png";
import dottedmap from "../assets/images/dottedmap.png";
import { IoLogoInstagram } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { BsTelephone } from "react-icons/bs";
import { CiFacebook } from "react-icons/ci";

function Footer() {
  return (
    <div id="footer" className="bg-slate-100">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 pt-10 pb-10 px-5 sm:px-10">
        <div>
          <div className="flex items-center">
            <a href="/" className="text-white font-bold flex-shrink-0">
              <img src={logo} alt="" className="w-4 h-4 sm:w-6 sm:h-6" />
            </a>
            <h2 className="font-semibold text-base sm:text-xl ml-2 sm:ml-3 text-gray-900">
              <span className="text-green-500 text-base sm:text-xl">V</span>UKA
              UMLIMI
            </h2>
          </div>
          <p
            className="my-4 text-xs sm:text-sm pr-0 sm:pr-20 text-gray-600"
            style={{ lineHeight: 1.8 }}
          >
            We strive to foster a strong bond between technology and
            agriculture, paving the way for a future where farming is both
            technologically advanced and environmentally conscious.
          </p>
        </div>
        <div>
          <h3 className="font-medium mb-3 sm:mb-6 text-gray-900">Company</h3>
          <ul className="text-sm text-gray-600">
            <li className="mb-2">
              <a href="/#services">Services</a>
            </li>
            <li className="mb-2">
              <a href="/about">About us</a>
            </li>
            <li className="mb-2">
              <a href="#footer">Contact</a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="font-medium mb-3 sm:mb-6 text-gray-900">Contacts</h3>
          <p className="text-sm flex mb-2 text-gray-600">
            <HiOutlineMail style={{ marginRight: "0.5rem" }} />
            Email:{" "}
            <a href="mailto:vukaumlimi@gmail.com">vukaumlimi@gmail.com</a>
          </p>
          <p className="text-sm flex mb-2 text-gray-600">
            <BsTelephone style={{ marginRight: "0.5rem" }} />
            Phone: <a href="tel:+265993093411">+265 (0) 88 70 75 923</a>
          </p>
          <div className="flex">
            <a href="https://www.facebook.com/profile.php?id=100094298912878&mibextid=ZbWKwL">
              <CiFacebook size={24} className="mr-5 text-gray-600" />
            </a>
            <a href="https://instagram.com/vukaumlimi?igshid=MzRlODBiNWFlZA==">
              <IoLogoInstagram size={24} className="mr-5 text-gray-600" />
            </a>
          </div>
        </div>
      </div>
      <div className="border-t-2 border-dashed mx-auto flex items-center justify-center text-gray-600">
        <p className="py-5 text-xs sm:text-sm">
          © 2023 Vuka Umlimi. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
