import React, { useState, useEffect } from "react";
import logo from "../assets/images/navlogo.png";
import vsign from "../assets/images/vsign.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      const nav = document.getElementById("navbar");
      if (nav) {
        if (scrolled) {
          nav.classList.add("scrolled");
        } else {
          nav.classList.remove("scrolled");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav id="navbar" className="navbar bg-transparent">
      <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-[60px]">
          <div className="flex items-center">
            <img src={vsign} alt="" srcSet="" className="h-12" />
          </div>
          <div className="hidden sm:block">
            <ul className="flex space-x-8 text-gray-600 font-[500]">
              <li>
                <Link to="/" className="block hover:text-green-500">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/services" className="block">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/blogposts" className="block">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/about" className="block">
                  About us
                </Link>
              </li>
              <li>
                <Link to="#footer" className="block">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="block text-gray-600 hover:text-green-500 focus:text-green-500 focus:outline-none"
            >
              <svg
                className={`h-6 w-6 ${isMenuOpen ? "hidden" : "block"}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div
            className={`absolute top-16 left-0 ${
              isMenuOpen ? "block" : "hidden"
            } bg-white w-full`}
          >
            <ul className="pt-2 pb-4 space-y-1">
              <li>
                <Link
                  to="/"
                  className="block px-4 py-2 text-gray-600 font-semibold hover:text-green-500"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="block px-4 py-2 text-gray-600 font-semibold hover:text-green-500"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/blogposts"
                  className="block px-4 py-2 text-gray-600 font-semibold hover:text-green-500"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="block px-4 py-2 text-gray-600 font-semibold hover:text-green-500"
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="block px-4 py-2 text-gray-600 font-semibold hover:text-green-500"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
