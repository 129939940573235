import vision from "../assets/images/vision.png";
import morale from "../assets/images/morale.png";
import mission from "../assets/images/mission.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function Welcome() {
  return (
    <div className="sm:flex items-center mx-auto px-5 sm:px-32 sm:my-24 my-8">
      <div className="mr-8">
        <h1 className="font-[500] hidden sm:block sm:font-[700] text-4xl sm:text-3xl mb-2 text-gray-900">
          Welcome to Vuka Umlimi
        </h1>
        <h1 className="font-[500] sm:hidden sm:font-[700] text-4xl sm:text-3xl mb-2 text-gray-900">
          <span className="text-2xl font-semibold">Welcome to </span> <br />{" "}
          Vuka Umlimi
        </h1>
        <p
          className="text-[1.125rem] mb-8 text-gray-600"
          style={{ lineHieght: "1.75rem" }}
        >
          A leading agritech company designed to improve and sustain agriculture
          through innovative technology.
        </p>
        <a href="/about" className="text-green-400">
          More about us <ArrowForwardIcon />
        </a>
      </div>
      <div className=" mt-12 sm:mt-0 sm:ml-8 ">
        <div className="flex items-center  mb-6">
          <img
            src={vision}
            alt=""
            className="h-12 bg-green-400 rounded-lg p-2 m-2 mr-5 shadow-lg"
          />
          <p className="text-[0.95rem] text-gray-600">
            Our vision is a future where farmers harness innovation to maximize
            yields and optimize resources.
          </p>
        </div>

        <div className="flex items-center  mb-6">
          <img
            src={mission}
            alt=""
            className="h-12 bg-green-400 rounded-lg p-2 m-2 mr-5 shadow-lg"
          />
          <p className="text-[0.95rem] text-gray-600">
            Our mission is to revolutionize agricultural production, empowering
            farmers with innovative technology solutions and high-quality
            products.
          </p>
        </div>
        <div className="flex items-center  mb-6">
          <img
            src={morale}
            alt=""
            className="h-12 bg-green-400 rounded-lg p-2 m-2 mr-5 shadow-lg"
          />
          <p className="text-[0.95rem] text-gray-600">
            Success is attainable when individuals receive the right amount of
            encouragement and support.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
