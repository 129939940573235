import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { RxDotFilled } from "react-icons/rx";
import { FaUserCircle } from "react-icons/fa";
import { Analytics } from "@vercel/analytics/react";
import { useSelector, useDispatch } from "react-redux";
import { setPosts } from "../features/slice/blogPostSlice";
import { useFetchPostsQuery } from "../features/api/blogPostApiSlice";
import EmptyAnimation from "../assets/lotties/Animation - 1709978147164.json";
import LoadingAnimation from "../assets/lotties/Animation - 1710081862551.json";
import { Link } from "react-router-dom";

function Blogs() {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useFetchPostsQuery();
  const [searchInput, setSearchInput] = useState("");
  const [selectedType, setSelectedType] = useState("all");
  const { posts } = useSelector((state) => state.blogPost);

  useEffect(() => {
    if (posts.length === 0 && data) {
      dispatch(setPosts(data));
    }
  }, [data, posts, dispatch]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleTypeFilterChange = (event) => {
    setSelectedType(event.target.value);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const filteredPosts = posts
    .filter((post) => {
      return (
        post.title.toLowerCase().includes(searchInput.toLowerCase()) &&
        (selectedType === "all" || post.type === selectedType)
      );
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const latestPost = filteredPosts.length > 0 ? filteredPosts[0] : null;
  const otherPosts = filteredPosts.slice(1);

  return (
    <div className="px-[4%] md:px-[6%]">
      <div className="h-[45vh] sm:h-[80vh] sm:mt-[2%] mt-[1%] sm:mb-[3%] mb-[5%] relative overflow-hidden rounded-3xl">
        {isLoading ? (
          <div className="flex flex-col text-center items-center justify-center">
            <Lottie
              animationData={LoadingAnimation}
              style={{
                width: "250px",
                height: "250px",
                justifyContent: "center",
              }}
            />
            <p>Fetching Posts...</p>
          </div>
        ) : (
          <>
            {latestPost &&
              latestPost.sections.find(
                (section) => section.type === "image"
              ) && (
                <Link
                  to={`/blog/${latestPost._id}`}
                  className="absolute inset-0 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${
                      latestPost.sections.find(
                        (section) => section.type === "image"
                      ).content
                    })`,
                  }}
                >
                  <div className="absolute inset-0 bg-black opacity-30"></div>
                  <div className="absolute bottom-0 left-0 right-0 p-3 md:p-4 md:px-8 text-white z-10">
                    <p className="text-white font-medium text-xs md:text-base mb-1 md:mb-4">
                      {latestPost.content}
                    </p>
                    <h1 className="text-xl md:text-5xl font-[500] md:mb-4 mb-2 leading-6 md:leading-[3.75rem]">
                      {latestPost.title}
                    </h1>
                    <p className="text-slate-100 text-sm md:text-base line-clamp-3 md:mb-4 mb-1 w-[70%]">
                      {latestPost.introduction}
                    </p>
                  </div>
                </Link>
              )}
          </>
        )}
      </div>
      <p className="font-[600] text-xl px-[2%] mb-[5%] md:mb-[2%]">
        Recent blog posts
      </p>
      {isLoading ? (
        <div className="flex flex-col text-center items-center justify-center">
          <Lottie
            animationData={LoadingAnimation}
            style={{
              width: "250px",
              height: "250px",
              justifyContent: "center",
            }}
          />
          <p>Fetching Posts...</p>
        </div>
      ) : isError ? (
        <div className="flex flex-col text-center items-center justify-center">
          <p>Failed to load posts.</p>
        </div>
      ) : (
        <>
          {otherPosts.length === 0 ? (
            <div className="flex flex-col text-center items-center justify-center">
              <Lottie
                animationData={EmptyAnimation}
                style={{
                  width: "250px",
                  height: "250px",
                  justifyContent: "center",
                }}
              />
              <p>No Posts available</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-[5%] px-[4%]">
              {otherPosts.map((postData, index) => {
                const sectionWithImage = postData.sections.find(
                  (section) => section.type === "image"
                );

                return (
                  <Link
                    to={`/blog/${postData._id}`}
                    key={index}
                    className="rounded-xl shadow-md p-2 bg-white"
                  >
                    {sectionWithImage ? (
                      <img
                        src={sectionWithImage.content}
                        alt={postData.title}
                        className="h-40 md:h-32 w-[100%] rounded-lg object-cover mb-2"
                      />
                    ) : (
                      <div className="h-32 w-[100%] bg-gray-300 rounded-lg mb-2"></div>
                    )}
                    <div>
                      <h1 className="text-base font-medium mb-2 line-clamp-1">
                        {postData.title}
                      </h1>
                      <p
                        className="text-gray-600 text-sm line-clamp-2 mb-4"
                        style={{ lineHeight: "1.2rem" }}
                      >
                        {postData.introduction}
                      </p>
                      <div className="flex align-middle items-center">
                        <FaUserCircle
                          className="text-gray-300 mr-1"
                          size={18}
                        />
                        <p className="text-gray-800 font-medium text-xs">
                          {postData.author}
                        </p>
                        <RxDotFilled className=" text-gray-800" size={12} />
                        <p className="text-gray-800 font-medium text-xs">
                          {formatDate(postData.createdAt)}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
        </>
      )}
      <Analytics />
    </div>
  );
}

export default Blogs;
