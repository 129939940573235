import { apiSlice } from "./apiSlice";

const BLOG_POST_URL = "api/blogposts";

export const blogPostApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchPosts: builder.query({
      query: (page) => `${BLOG_POST_URL}/`,
    }),
    fetchPostCount: builder.query({
      query: () => `${BLOG_POST_URL}/posts/count`,
    }),
    createPost: builder.mutation({
      query: (data) => ({
        url: `${BLOG_POST_URL}/`,
        method: "POST",
        body: data,
      }),
    }),
    updatePost: builder.mutation({
      query: ({ id, data }) => ({
        url: `${BLOG_POST_URL}/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deletePost: builder.mutation({
      query: (id) => ({
        url: `${BLOG_POST_URL}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useFetchPostsQuery,
  useFetchPostCountQuery,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
} = blogPostApiSlice;
