import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: { value: "", hasError: true, touched: false, error: "" },
  subject: { value: "", hasError: true, touched: false, error: "" },
  message: { value: "", hasError: true, touched: false, error: "" },
  isFormValid: false,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setContactData(state, action) {
      const { name, value, hasError, error, touched, isFormValid } =
        action.payload;
      return {
        ...state,
        [name]: { ...state[name], value, hasError, error, touched },
        isFormValid,
      };
    },
    resetForm(state, action) {
      return {
        email: { value: "", hasError: true, touched: false, error: "" },
        subject: { value: "", hasError: true, touched: false, error: "" },
        message: { value: "", hasError: true, touched: false, error: "" },
        isFormValid: false,
      };
    },
  },
});

export const { setContactData, resetForm } = contactSlice.actions;

export default contactSlice.reducer;
