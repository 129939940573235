import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { useParams } from "react-router-dom";
import { IoPersonSharp } from "react-icons/io5";
import { Analytics } from "@vercel/analytics/react";
import { useSelector, useDispatch } from "react-redux";
import { setPosts } from "../features/slice/blogPostSlice";
import { useFetchPostsQuery } from "../features/api/blogPostApiSlice";
import EmptyAnimation from "../assets/lotties/Animation - 1709978147164.json";
import LoadingAnimation from "../assets/lotties/Animation - 1710081862551.json";

function BlogPostView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useFetchPostsQuery();
  const { posts } = useSelector((state) => state.blogPost);
  const blogPost = posts.find((p) => p._id === id);

  useEffect(() => {
    if (posts.length === 0 && data) {
      dispatch(setPosts(data));
    }
  }, [data, posts, dispatch]);

  if (!blogPost) {
    return isLoading ? (
      <div className="flex flex-col text-center items-center justify-center">
        <Lottie
          animationData={LoadingAnimation}
          style={{
            width: "250px",
            height: "250px",
            justifyContent: "center",
          }}
        />
        <p>Fetching Posts</p>
      </div>
    ) : (
      <div>No bid data available</div>
    );
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const renderSection = (section, index) => {
    switch (section.type) {
      case "heading":
        return (
          <h2 key={index} className="text-lg md:text-xl font-semibold mb-2">
            {section.content}
          </h2>
        );
      case "image":
        return (
          <div key={index} className="sm:flex justify-center mb-5">
            <img
              src={section.content}
              alt="section"
              className="rounded-xl w-full sm:h-[60vh] object-cover"
            />
          </div>
        );
      case "paragraph":
        return (
          <div key={index} className="mb-2 md:mb-5">
            {section.content.split(/\r?\n/).map((paragraph, index) => (
              <p
                key={index}
                className="text-[0.95rem] text-gray-600 overflow-hidden"
                style={{ lineHeight: "1.75rem" }}
              >
                {paragraph}
              </p>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="px-8 sm:px-36 my-8">
      <h1 className="font-[700] text-green-600 text-2xl sm:text-4xl mb-2">
        {blogPost.title}
      </h1>
      <div className="mb-5 flex items-center">
        <IoPersonSharp className="h-4 text-gray-400 mr-2" />
        <p className="text-gray-400 text-sm italic">
          Authors : {blogPost.author}
        </p>
        <span className="mx-2">•</span>
        <p className="text-gray-400 text-sm italic">
          {formatDate(blogPost.createdAt)}
        </p>
      </div>
      <p
        className="text-[0.95rem] mb-5 text-gray-600 italic"
        style={{ lineHeight: "1.75rem" }}
      >
        {blogPost.introduction}
      </p>

      {blogPost.sections.map((section, index) =>
        renderSection(
          blogPost.sections[blogPost.sections.length - 1 - index],
          index
        )
      )}
      <Analytics />
    </div>
  );
}

export default BlogPostView;
