import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import authReducer from "../features/slice/authSlice";
import contactSlice from "../features/slice/contactSlice";
import blogPostReducer from "../features/slice/blogPostSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contact: contactSlice,
    blogPost: blogPostReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
