import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBDb4SGTyFDJZqEa_QSPjzFM6vWfPau-Us",
  authDomain: "vweb-bf99d.firebaseapp.com",
  projectId: "vweb-bf99d",
  storageBucket: "vweb-bf99d.appspot.com",
  messagingSenderId: "734381060300",
  appId: "1:734381060300:web:b1ebcbdda2400282e21269",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
