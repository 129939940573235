import React from 'react';

function Terms() {
  return (
    <div>
      <h1>Terms and Conditions of Use for Poultry Pro</h1>
      <p>Last Updated: 15 September 2023</p>
      <p>
        These Terms and Conditions of Use ("Terms") govern your access to and use of the Poultry Pro mobile application (the "App") provided by Vuka Umlimi ("Company," "we," "our," or "us"). By accessing or using the App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the App.
      </p>

      <h2>1. User Eligibility</h2>
      <p>
        <strong>1.1. You must be at least 18 years old to use this App. By using the App, you represent and warrant that you are of legal age in your jurisdiction to enter into these Terms.</strong>
      </p>

      <h2>2. App Usage</h2>
      <p>
        <strong>2.1. The App is intended for use by poultry farmers and related agricultural businesses for managing poultry farm operations. You agree not to use the App for any illegal or unauthorized purposes.</strong>
      </p>
      <p>
        <strong>2.2. You are responsible for maintaining the confidentiality of your account and password, and you agree to accept responsibility for all activities that occur under your account.</strong>
      </p>

      <h2>3. Privacy</h2>
      <p>
        <strong>3.1. Your use of the App is also governed by our Privacy Policy, which is incorporated by reference into these Terms. Please review our Privacy Policy to understand our practices concerning your personal information.</strong>
      </p>

      <h2>4. Content Ownership</h2>
      <p>
        <strong>4.1. The App, its content, features, and functionality are owned by the Company or its licensors and are protected by copyright, trademark, and other intellectual property laws.</strong>
      </p>

      <h2>5. Limitation of Liability</h2>
      <p>
        <strong>5.1. To the fullest extent permitted by applicable law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.</strong>
      </p>

      <h2>6. Termination</h2>
      <p>
        <strong>6.1. We reserve the right to terminate or suspend your account and access to the App at our sole discretion, without notice, for any reason, including if we believe you have violated these Terms.</strong>
      </p>

      <h2>7. Changes to Terms</h2>
      <p>
        <strong>7.1. We reserve the right to modify or revise these Terms at any time. We will notify you of any changes by posting the updated Terms within the App. Your continued use of the App after such modifications will constitute your acknowledgment of the modified Terms and your agreement to be bound by them.</strong>
      </p>

      <h2>8. Contact Information</h2>
      <p>
        <strong>8.1. If you have any questions about these Terms, please contact us at <a href="mailto:vukaumlimi@gmail.com">vukaumlimi@gmail.com</a>.</strong>
      </p>

      <p>By using the Poultry Pro app, you agree to abide by these Terms and Conditions of Use. Please read them carefully and ensure your compliance.</p>
    </div>
  );
}

export default Terms;
